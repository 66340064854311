import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../layouts/index';
import translationsEn from '../locales/en.json';
import translationsEs from '../locales/es.json';

const ResetPassword = () => {
  const [locale, setLocale] = useState('es');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [token, setToken] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [submissionResult, setSubmissionResult] = useState('');

  const staticData = useStaticQuery(graphql`
    query {
      site: datoCmsSite {
        faviconMetaTags {
          tags
        }
        globalSeo {
          siteName
        }
      }
    }
  `);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedLocale = localStorage.getItem('locale') || 'es';
      setLocale(storedLocale);
    }

    const handleLanguageChange = (event) => {
      setLocale(event.detail);
    };

    window.addEventListener('languageChanged', handleLanguageChange);
    return () => window.removeEventListener('languageChanged', handleLanguageChange);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');

    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
  }, [staticData, locale]);

  const handleUpdatePassword = async () => {
    if (!token) {
      setErrorMessage("Invalid token");
      return;
    }
    
    try {
      const response = await axios.post('https://api.osdocasal.com:8090/send-reset-password', {
        password,
        token,
      });
      console.log(response);
      setSubmissionResult(translations['update-password']);
      setShowModal(true);
    } catch (error) {
      console.error('Update password error:', error);
      setSubmissionResult(translations['update-password-failed']);
      setShowModal(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }
    handleUpdatePassword();
  };

  const translations = locale === 'es' ? translationsEs : translationsEn;

  return (
    <Layout
      site={staticData.site}
      seo={{ ...staticData.site.globalSeo, ...staticData.site.faviconMetaTags }}
      lang={locale}
      title={"Restablecer contraseña - OS DO CASAL"}
      description={"Restablecer contraseña"}
      noIndex={true}
    >
      <div className="Reset-password">
        <form onSubmit={handleSubmit} className="reset-password-form">
          <div className="input-container">
            <label htmlFor="password">{translations['password']}</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="confirmPassword">{translations['confirm-password']}</label>
            <input
              id="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div>
            <button type="submit">{translations['send']}</button>
          </div>
        </form>
        {showModal && (
          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '999',
            backgroundColor: '#fff',
            padding: '20px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            border: '1px solid #000'
          }}>
            <p style={{color: '#000'}}>{submissionResult}</p>
            <button type="button" onClick={() => setShowModal(false)}>{translations['close']}</button>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ResetPassword;